
/* SCSS RGB */
$palette1: rgba(15, 66, 153, 1); //dark-cornflower-blue
$palette2: rgba(54, 58, 64, 1); //onyx
$palette3: rgba(216, 221, 230, 1); //gainsboro
$palette4: rgba(85, 114, 166, 1); //blue-yonder
$palette5: rgba(35, 42, 54, 1); //gunmetal
$palette6: rgba(17, 184, 198, 1);
$palette7: rgba(90, 20, 210, 1);
$palette8: rgba(71, 134, 86, 1);
$palette9: rgba(197, 232, 246, 1);


/* MEDIA QUERIES INTERVALS */
$layout-breakpoint-extra-small: 376px;
$layout-breakpoint-small: 576px;
$layout-breakpoint-medium: 768px;
$layout-breakpoint-large: 992px;
$layout-breakpoint-extra-large: 1200px;