@import '../../assets/styles/base';

.pageContainer {
    background-color: $palette4;
    min-height: calc((100vh - 168px + 2rem)/2);
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
