@import '../../assets/styles/base';

.pageContainer{
    min-height: calc(100vh - 168px + 2rem);
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: $palette5;
    & h1 {
        color: $palette4;
    }
    & p {
        color: $palette3;
        padding: 0 2rem 2rem;
        text-align: justify;

    }     
}


@media (width > $layout-breakpoint-large) {
    .pageContainer{
        & h1{
            font-size: 2.5rem;
        }
        & p{
            font-size:1.5rem;
            padding: 2rem 15rem 4rem;
        }
    }
}