@import '../../assets/styles/base';

.hidden{ 
    position: absolute;
    top: -126px;
    visibility: hidden;
    height: 0;
    width: 0;
}

.pageContainer {
    height: calc(100vh - 168px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: $palette2;
    overflow: hidden;    
    
    .cardContainer {
        width:70%;
        height: 80%;;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
    }

    

    
}

@media (width > $layout-breakpoint-large) {

    .hidden {
        top: 0;
    }
    .cardContainer {
        font-size: 1.25rem;        
        img{
            height: 15rem;
        }
    }
}

@media (width > $layout-breakpoint-extra-large) {
    .cardContainer {
        width: 50%;
        height: 60%;
    }
}