@import '../assets/styles/base';

body {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    background-color: $palette2;
    h1 {
        font-weight: bold;
        font-size: larger;
        font-size: 1.5em;
        padding: 1rem 0;        
    }
    p {
        padding-bottom: 1rem;
    }
}

.app{    
    position: absolute;
    top: 0;
    width: 100%;
    overflow: hidden;
    .navbar{
        box-sizing: border-box;
        width: 100%;
        height: 11.6rem;
        position: fixed;
        z-index: 1000;
        opacity: .95;
    }
    .content{
        margin-top: 11.6rem;
        height: calc(100vh - 11.6rem);
        overflow: scroll;

        h1:target {
            &::before {
                content: '';
                display: block;
                height: 6rem;
            }
        }
    }
}

@media (width >  635px) {
    .app{
        .navbar{
            height: 10rem;
        }
        .content{
            height: calc(100vh - 10rem);
            margin-top: 10rem;
        }
    }
}

@media (width > 635px) {
    .app{
        .navbar{
            height: 10rem;
        }
        .content{
            height: calc(100vh - 10rem);
            margin-top: 10rem;
        }
    }}
    
    
@media (width >  $layout-breakpoint-medium) {
    .app{
        .navbar{
            height: 10rem;
        }
        .content{
            height: calc(100vh - 10rem);
            margin-top: 10rem;
        }
    }
}