@import '../../assets/styles/base';
    
    .businesscard {     
        /* glassmorphism*/
        background-image: linear-gradient(to bottom right, rgba( 54, 58, 64, 0.15) 10%, rgba( 54, 58, 64, 0.75) 70%, rgba( 54, 58, 64, 0.95)  );
        //background: rgba( 54, 58, 64, 0.65 );
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 5px );
        -webkit-backdrop-filter: blur( 5px );
        border-radius: 10px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        /*scss*/
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding: 1rem;
        color: $palette3;    
        .image {    
            height: 6rem;
            margin-right: 1em;
        }
        .information {
            display: flex;
            flex-direction: column;
            justify-content: space-between;            
            flex-wrap: wrap;                        
            & li {
                padding: .15rem 0;
                display: flex;
                flex-wrap: wrap;
                word-wrap: break-word;
                font-style: italic;                    
                }   
            & li:first-child {
                font-size: 1.5em;
                color: $palette4;
                font-weight: bold;
                font-style:normal;   
            }            
        }
        &Vertical {
            flex-direction: column;          
            & .image {              
                margin-right: 0;
                height: auto;
                max-height: 7rem;
                max-width: 100%;
            }            
            & .information {
                display: flex;
                align-items: center;
                justify-content: center;

                & li:first-child {
                    color: $palette2;
                }
            }
        }
    }

@media (width < $layout-breakpoint-extra-small) {
    .information {
        font-size: .7rem;
    }
}

@media (width > $layout-breakpoint-small) {
    .businesscard {
        .information {
            margin: 2rem;
            & li{
                margin: .3rem;                
            }
        }
    }    
}

@media (width > $layout-breakpoint-large) {      
        .businesscard {
            .image {
                height: 10rem;
            }
            &Vertical {
               & .image {
                   max-width: 100%;
                   max-height: 12rem;
                   margin: 0 auto;
               }   
            }
            
            .information {                           
                & li {
                    font-size: 1rem;                
                    }   
                & li:first-child {
                    font-size: 1.5rem;                    
                }
             }
        }
    }    


@media (width > $layout-breakpoint-extra-large) {          
        .businesscard {
            .information {                           
                & li {
                    font-size: 1.3rem;                
                    }   
                & li:first-child {
                    font-size: 1.8rem;                    
                }
             }
        }
    }    
