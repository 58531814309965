@import '../../../assets/styles/base';

.menu {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        box-sizing: border-box;
        width: 25%;
        height: 4rem;
        border-right: 1px solid $palette3;
        padding: .3rem;
        a {
            text-decoration: none;
            color: $palette3;

            &:visited {
                color: $palette3;
            }
        }    
    }
    &__list:hover {
        font-size: large;
        font-weight: bold;
        font-style: italic;
    }
    &__list:last-child {
        border-right-width: 0px;
    }  
}

@media (width > 635px) {
    .menu__list {
        border: none;
        margin: .3em;
    }
}