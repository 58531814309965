@import '../../assets/styles/base';

.pageContainer {
    align-self: flex-start;
    width: 100%;
    min-height: calc(100vh - 168px + 2rem);
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: $palette3;
    z-index: 100;
    & h1 {        
        color: $palette2;
    }

    & p {
        color: $palette2;
        padding: 0 2rem 2rem;
        text-align: justify;
    }
}


@media (width > $layout-breakpoint-large) {
    .pageContainer{
        & h1 {
            font-size: 2.5rem;
        }
        & p{
            font-size:1.5rem;
            padding: 2rem 15rem 4rem;
        }
    }
}
